import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import 'font-awesome/css/font-awesome.min.css';
import { BrowserRouter } from "react-router-dom"
import { store } from './redux/store'
import { Provider } from 'react-redux'

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.Fragment>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.Fragment>
)
