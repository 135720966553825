import React from "react"
import verificationImg from "../../assets/images/verification-img.png";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

const ResponseJobApplication = () => {
    const goBackEvent = () => {
        history.back();
    }
    return (
        <React.Fragment>
        <div className="page-content">
            <Container fluid>
            <Row className="justify-content-center mt-lg-5">
                <Col xl="5" sm="8">
                <Card>
                    <CardBody>
                    <div className="text-center">
                        <Row className="justify-content-center">
                        <Col lg="10">
                            <h4 className="mt-4 fw-semibold">
                            Job Submitted Successfully
                            </h4>
                            <p className="text-muted mt-3">
                                Thank You for Submitting Your Application
                            </p>
                            <div className="mt-4">
                            <Button
                                type="button"
                                color="primary"
                                onClick={goBackEvent}
                                >
                                Go Back
                            </Button>
                            </div>
                        </Col>
                        </Row>

                        <Row className="justify-content-center mt-5 mb-2">
                        <Col sm="6" xs="8">
                            <div>
                            <img
                                src={verificationImg}
                                alt=""
                                className="img-fluid"
                            />
                            </div>
                        </Col>
                        </Row>
                    </div>
                    </CardBody>
                </Card>
                </Col>
            </Row>          
            </Container>
        </div>
        </React.Fragment>
    )
}

export default ResponseJobApplication
