import React from "react"
import { Modal, ModalBody } from "reactstrap"

const JobDescriptionModal = ({ toggle, modal, jobDescription, jobTitle }) => {
  // Splitting job description into paragraphs

    const insertNewLineBeforeHeadings = (description) => {
    const lines = description.split('\n');
    const modifiedLines = [];

    lines.forEach((line, index) => {
      if (line.trim().endsWith(':')) {
        modifiedLines.push(`NEWLINE**${line}`);
      } else {
        modifiedLines.push(line);
      }
    });

    return modifiedLines.join('\n');
  };

  const formattedDescription = insertNewLineBeforeHeadings(jobDescription);


  const paragraphs = formattedDescription.split('\n').map((paragraph, index) => (
    <p key={index} style={{
          textAlign: 'justify',
          textJustify: 'inter-word',
          padding: '0px 8px',
          margin: '5px 0', // Adjust line spacing as needed
        }}>
      {paragraph.startsWith('NEWLINE**') ? (
         <> <br/> <b>{paragraph.replace('NEWLINE**', '')}</b></>
        ) : (
          paragraph
        )}
    </p>
  ));

  return (
    <Modal isOpen={modal} toggle={toggle} size="lg">
      <ModalBody>
        <div>
          <h3>Job Title:</h3>
          <b>{jobTitle}</b>
          <br />
          <h3>Job Description:</h3>
          {/* Rendering formatted paragraphs */}
          {paragraphs}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default JobDescriptionModal