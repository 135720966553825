// src/components/filter.
import React, { useState, useEffect, useMemo } from "react"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import useDebounce from "../../components/Common/addDelay"

//import components
import CandidatesTableContainer from "../../components/Common/CandidatesTableContainer"

import axiosInstance from "service/axiosConfig"
import {Badge, Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Row} from "reactstrap"
import Loader from "components/Common/loader"
import JobApplicationAuthenticatedModal from "../../components/Common/JobApplicationAuthenticatedModal";
import JobDescriptionModal from "../../components/Common/JobDescriptionModal";
import ResumeModal from "../../components/Common/ResumeModal";

function CandidatesTable() {
  const [isLoading, SetIsLoading] = useState(false)
  const [data, setData] = useState([]) // State for storing the data records
  const [totalPages, setTotalPages] = useState(0) // State for storing total pages
  const [page, setPage] = useState(1) // Current page
  const [pageSize, setPageSize] = useState(10) // Records per page
  const [searchQuery, setSearchQuery] = React.useState("")
  const debouncedSearchQuery = useDebounce(searchQuery, 300)
  const navigate = useNavigate()
  const location = useLocation()
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [isResumesUploaded, setIsResumesUploaded] = useState(false)
  const [status, setStatus] = useState("")
  const [filterValue, setFilterValue] = useState("")
  const [currentJob, setCurrentJob] = useState()
  const [refreshData, setRefreshData] = useState(false);
  const [isStatusUpdate, setIsStatusUpdate] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0) // display the total count of records
  const { job_id } = useParams()
  const jobTitle = location.state?.jobTitle || "Default Job Title"

  function getCandidates(){
    axiosInstance
      .get(
        `jobs/retrieve_candidates_for_job?page=${page}&pageSize=${pageSize}&job_id=${job_id}&search=${debouncedSearchQuery}&status=${status}`
      )
      .then(response => {
        SetIsLoading(false)
        setData(response.data.records)
        setTotalPages(response.data.totalPages)
        setTotalRecords(response.data.total_records)
        // console.log("Response from backend: ", response.data.records )
      })
      .catch(error => {
        SetIsLoading(false)
        console.error("There was an error fetching the jobs", error)
      })
  }

  useEffect(() => {
    if (location.state?.success === "True") {
      setShowSuccessMessage(true)

      // You can now clear the success flag to avoid showing it again when revisiting this route:
      location.state.success = "False"
    }
  }, [location.state])
  // This use effect will be updated in future
  useEffect(() => {
  const intervalId = setInterval(() => {
    console.log("START")
    // Fetch candidates
    axiosInstance
      .get(`jobs/retrieve_candidates_for_job?page=${page}&pageSize=${pageSize}&job_id=${job_id}&search=${debouncedSearchQuery}&status=${status}`)
      .then(response => {
        console.log("RES", response.data)
        const hasZeroTotalScore = response.data.records.some(candidate => candidate.total_score === 0);

        // If any candidate has total_score equal to zero, execute the function
        if (hasZeroTotalScore) {
          console.log("AGAIN")
          getCandidates();
        } else {
          getCandidates();
          console.log("END")
          // Stop the interval as no candidate has total_score equal to zero
          clearInterval(intervalId);
        }
      })
      .catch(error => {
        console.error("There was an error fetching the jobs", error);
      });
  }, 5000);

  // Cleanup the interval on component unmount
  return () => clearInterval(intervalId);
}, [isResumesUploaded]);

  useEffect(() => {
    SetIsLoading(true)
    axiosInstance
      .get(`/jobs/get_job/${job_id}`)
      .then(response => {
        setCurrentJob(response.data)
      })
      .catch(error => {
        console.error("There was an error fetching the Job", error)
      })

   getCandidates()
  }, [page, pageSize, status, debouncedSearchQuery, refreshData, filterValue, isResumesUploaded])

  function getTextColorBasedOnBackground(backgroundColor) {
    // Convert the HEX background color to RGB values
    const red = parseInt(backgroundColor.slice(1, 3), 16)
    const green = parseInt(backgroundColor.slice(3, 5), 16)
    const blue = parseInt(backgroundColor.slice(5, 7), 16)

    // Calculate the relative luminance
    const luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255

    // Determine the text color based on luminance
    return luminance > 0.5 ? "black" : "white"
  }

  const totalScoreColorCode = score => {
    if (score < 15) {
      return "#FF0E0E"
    } else if (score < 25) {
      return "#FFCC00"
    } else {
      return "#4BB543"
    }
  }

  const transformedData = data?.map(candidate => ({
    id: candidate.guid,
    name: candidate.name,
    email: candidate.email,
    score: {
      total_score: candidate.total_score,
      total_score_color: totalScoreColorCode(candidate.total_score),
    },
    status: {
      ...candidate.status,  
      textColor: getTextColorBasedOnBackground(candidate.status.color),
    },
    response: candidate.response,
    resume: candidate.resume,
  }))

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Score",
        accessor: "score",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  )
  const [addNewResumeModal, setAddNewResumeModal] = useState(false)
    const toggleAddNewResume = () => setAddNewResumeModal(!addNewResumeModal)
    const [jobDescriptionModal, setjobDescriptionModal] = useState(false)
    const toggleShowJobDescription = () =>
    setjobDescriptionModal(!jobDescriptionModal)
  const [showResumeModal, setShowResumeModal] = useState(false)
    const toggleShowResumeModal = () => setShowResumeModal(!showResumeModal)
    const [selectedDocumentUrl, setSelectedDocumentUrl] = useState("")
  const [isHovered, setIsHovered] = useState(false);
  const buttonStyle = {
    textAlign: 'center',
    backgroundColor: isHovered ? '#556ee6' : '#D4DBF9',
    border: 'none',
    color: isHovered ? '#fff' : '#545AFB',
    fontSize: '15px',
  };
  const handleSearchChange = event => {
    setSearchQuery(event.target.value)
    setPage(1)
  }
const clearSearch = () => {
    setSearchQuery('');
  };
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen)
  const [selectedCandidated, setSelectedCandidated] = useState([])
  const [filters, setFilters] = useState([])
  const updateCandidatesStatus = value => {
    axiosInstance
      .put(`jobs/candidate_status`, {
        candidates: selectedCandidated,
        value: value,
      })
      .then(response => {
        setFilterValue(value)
      })
      .catch(error => {
        console.error("There was an error fetching the statuses", error)
      })
  }
   const [statuses, setStatuses] = useState([])

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Row className="mb-4">
            <Col xs="12" md="6" className="d-flex align-items-center">
              <h2 style={{ fontWeight: "bold" }}>Candidates</h2>
              <h3 style={{ fontWeight: "bold" }} className="mx-2">
                <Badge color="light">{totalRecords}</Badge>
              </h3>
            </Col>
          </Row>
        {/* <Breadcrumbs title="View All Jobs" breadcrumbItem="Data Tables" /> */}

        <Card>
          <CardBody>
            {addNewResumeModal && (
        <JobApplicationAuthenticatedModal
          toggle={toggleAddNewResume}
          modal={addNewResumeModal}
          isResumesUploaded={isResumesUploaded}
          setIsResumesUploaded={setIsResumesUploaded}
        />
      )}

      {jobDescriptionModal && (
        <JobDescriptionModal
          toggle={toggleShowJobDescription}
          modal={jobDescriptionModal}
          jobDescription={currentJob?.job_description}
          jobTitle={currentJob?.title}
        />
      )}

      {showSuccessMessage && (
        <div className="alert alert-success">Job created successfully!</div>
      )}
      {isResumesUploaded && (
        <div className="alert alert-success">Resumes added successfully!</div>
      )}
       {isStatusUpdate && (
        <div className="alert alert-success">Status has been updated</div>
      )}
      {showResumeModal && (
        <ResumeModal
          modal={showResumeModal}
          toggle={toggleShowResumeModal}
          documentUrl={selectedDocumentUrl}
        />
      )}
      <div className="d-flex justify-content-between py-4">
        <div className="d-flex gap-4">
          <button className="btn btn-primary" onClick={() => navigate("/home")}>
              {"< " + jobTitle}
            </button>
            <button
            className="btn"
            style={buttonStyle}
            onClick={toggleShowJobDescription}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            View Job
          </button>
        </div>
        <button
          className="btn btn-primary"
          style={{
            width: "160px",
            padding: "0.375rem 0.75rem",
            textAlign: "center",
          }}
          onClick={toggleAddNewResume}
        >
          Add New Resumes
        </button>
      </div>

      <div className="d-flex gap-2 justify-content-between py-2">
        <div className="d-flex gap-2">
          <div className="row">
            <div className="col-md-9">
              <Input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  style={{
                    width: "250px",
                    paddingRight: "30px", // Ensure space for the clear button
                  }}
                  autoFocus={true}
              />
            </div>
            <div className="col-md-2 py-2">
              {searchQuery && (
                  <i
                      className="fas fa-times"
                      onClick={clearSearch}
                      style={{cursor: 'pointer'}}
                      aria-label="Clear search"
                      role="button"
                  ></i>
              )}
            </div>
          </div>

          <Input
              className="form-control"
              style={{
                width: "fit-content",
              }}
              type="select"
              value={status}
              onChange={e => setStatus(e.target.value)}
          >
            {statuses.map(size => (
                <option key={size} value={size}>
                  {size}
                </option>
            ))}
          </Input>
        </div>
        <Dropdown
            isOpen={isDropdownOpen}
            toggle={toggleDropdown}
            disabled={!selectedCandidated.length}
            className={!selectedCandidated.length ? "d-none" : "d-block"}
        >
          <DropdownToggle
              tag="button"
              className="btn btn-warning"
              style={{
                padding: "6px 15px",
              }}
          >
            Change Status to <i className="mdi mdi-chevron-down"/>
          </DropdownToggle>
          <DropdownMenu>
            {filters.map(size => (
                <DropdownItem
                    key={size}
                    onClick={() => updateCandidatesStatus(size)}
                >
                  {size}
                </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
            {isLoading ? (
                <Loader loaderColor={"text-dark"}/>
            ) : (
                <CandidatesTableContainer
                    columns={columns}
                    data={transformedData}
                    jobTitle={currentJob?.title}
                currentPage={page}
                setCurrentPage={setPage}
                currentPageSize={pageSize}
                setCurrentPageSize={setPageSize}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                totalPages={totalPages}
                showSuccessMessage={showSuccessMessage}
                isResumesUploaded={isResumesUploaded}
                setIsResumesUploaded={setIsResumesUploaded}
                setRefreshData = {setRefreshData}
                className="custom-header-css"
                setStatus={setStatus}
                status={status}
                setFilterValue={setFilterValue}
                filterValue={filterValue}
                currentJob={currentJob}
                isStatusUpdate={isStatusUpdate}
                setIsStatusUpdate={setIsStatusUpdate}
                setSelectedDocumentUrl={setSelectedDocumentUrl}
                setSelectedCandidated={setSelectedCandidated}
                setFilters={setFilters}
                setStatuses={setStatuses}
                statuses={statuses}
                selectedCandidated={selectedCandidated}
                selectedDocumentUrl={selectedDocumentUrl}
                toggleAddNewResume={toggleAddNewResume}
              />
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default CandidatesTable
