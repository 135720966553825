import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
  Alert,
  InputGroup,
} from "reactstrap"

//Import Date Picker
import "react-datepicker/dist/react-datepicker.css"
//Import Breadcrumb
import axiosInstance from "service/axiosConfig"
import Loader from "components/Common/loader"
import Swal from "sweetalert2"
import { NONE } from "draft-js/lib/SampleDraftInlineStyle";

const JobApplication = () => {
  //meta title
  document.title = "Apply for Job | R24"
  const params = useParams()
  const [data, setData] = useState({})
  const [fullName, setFullName] = useState("")
  const [isFullName, SetIsFullName] = useState(false)
  const [phone, setPhone] = useState("")
  const [isPhone, SetIsPhone] = useState(false)
  const [email, setEmail] = useState("")
  const [isEmail, SetIsEmail] = useState(false)
  const [isFile, SetIsFile] = useState(false)
  const [errors, SetErrors] = useState([])
  const [isLoading, SetIsLoading] = useState(false)
  const [isViewLess, SetIsViewLess] = useState(false);
  const history = useNavigate();
  const [isDropZoneVisible, setIsDropZoneVisible] = useState(true)


  const cancel = () => {
    history("/")
  }

  const toggleViewMore = () => {
    SetIsViewLess(!isViewLess);
  }

  const goToJobListings = () => {
    history(`/jobs/${params.job_id}`)
  }

  const renderJobDescription = () => {
    const jobDescription = data?.job_description || ''; // Replace 'data?.job_description' with your actual job description string

    // Function to insert a new line before headings (lines ending with a colon)
    const insertNewLineBeforeHeadings = (description) => {
      const lines = description.split('\n');
      const modifiedLines = [];

      lines.forEach((line, index) => {
        if (line.trim().endsWith(':')) {
          modifiedLines.push(`NEWLINE**${line}`);
        } else {
          modifiedLines.push(line);
        }
      });

      return modifiedLines.join('\n');
    };

    const formattedDescription = insertNewLineBeforeHeadings(jobDescription);

    const truncatedDescription =
      formattedDescription.length > 270
        ? `${formattedDescription.slice(0, 270)}...`
        : formattedDescription;

    const paragraphs = (isViewLess ? formattedDescription : truncatedDescription)
      .split('\n')
      .map((paragraph, index) => (
        <p
          key={index}
          style={{
            textAlign: 'justify',
            textJustify: 'inter-word',
            padding: '0px 8px',
            margin: '5px 0', // Adjust line spacing as needed
          }}
        >
          {paragraph.startsWith('NEWLINE**') ? (
            <> <br /> <b>{paragraph.replace('NEWLINE**', '')}</b></>
          ) : (
            paragraph
          )}
        </p>
      ));

    return (
      <div>
        {paragraphs}
        {formattedDescription.length > 270 && (
          <Link className="text-primary" onClick={toggleViewMore}>
            {isViewLess ? <span>View Less</span> : <span>View More</span>}
          </Link>
        )}
      </div>
    );
  };

  useEffect(() => {
    SetIsLoading(true)
    const { job_id } = params
    axiosInstance
      .get(`jobs/get_job/${job_id}`)
      .then(response => {
        SetIsLoading(false)
        if (response.response?.status >= 400) {
          history("/page404")
        } else {
          setData(response.data)
        }
      })
      .catch(error => {
        SetIsLoading(false)
        console.log("there was an error", error)
      })
  }, [])

  const [selectedFiles, setselectedFiles] = useState([])

  function handleAcceptedFiles(files) {
    const acceptedFileTypes = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "text/plain",
    ]

    const imageFileTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/bmp",
      "image/tiff",
      "image/webp",
      // ... add any other image types you want to restrict
    ]

    let invalidFile = false

    files.map(file => {
      if (acceptedFileTypes.includes(file.type)) {
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      } else if (imageFileTypes.includes(file.type)) {
        invalidFile = true
      }
    })

    if (invalidFile) {
      Swal.fire({
        icon: 'error',
        text: 'Image files are not allowed. Please upload a valid file (PDF, DOCX, or TXT)'
      })
      return // Exit the function early
    }

    if (!files.length) {
      Swal.fire({
        icon: 'error',
        text: 'You can only upload one document at a time.'
      })
      return
    }

    setselectedFiles(prevFiles => [...prevFiles, ...files])
    setIsDropZoneVisible(false)
  }

  const removeFile = index => {
    const newFiles = [...selectedFiles]
    newFiles.splice(index, 1)
    setselectedFiles(newFiles)
    setIsDropZoneVisible(true)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const ResetValidation = () => {
    SetIsFullName(false)
    SetIsPhone(false)
    SetIsEmail(false)
    SetIsFile(false)
  }

  const applyJob = e => {
    e.preventDefault()
    SetErrors([])
    ResetValidation()
    const file = selectedFiles[0]
    let formValid = true
    if (!fullName) {
      SetIsFullName(true)
      formValid = false
    }
    if (!email) {
      SetIsEmail(true)
      formValid = false
    }
    if (!phone) {
      SetIsPhone(true)
      formValid = false
    }
    if (!file) {
      SetIsFile(true)
      formValid = false
    }

    if (!formValid)
      return

    SetIsLoading(true)
    const { job_id } = params
    const formData = new FormData()
    formData.append("name", fullName)
    formData.append("email", email)
    formData.append("phone", phone)
    formData.append("applied_job", job_id)
    formData.append("resume", file, file.name)
    axiosInstance
      .post("/jobs/apply_job", formData)
      .then(response => {
        SetIsLoading(false)
        if (response?.status === 201 && response?.data) {
          // var resp_data = response.data;
          // SetMessage(resp_data?.message);
          history("/job_application_response")
        }
      })
      .catch(error => {
        SetIsLoading(false)
        var final_errors = [];
        var error_list = error?.response?.data;
        if (error_list) {
          for (let key in error_list) {
            var error = error_list[key][0];
            final_errors.push(error);
          }
          SetErrors(final_errors);
        }
      })
  }
  console.log(selectedFiles)

  return (
    <React.Fragment>
      <section className="section hero-section bg-ico-hero" id="home">
        <div className="bg-overlay bg-primary"></div>
        <Container>
          {errors.map((value, index) => (
            <Alert key={index} color="danger">
              {value}
            </Alert>
          ))}
          <Row className="align-items-center">
            <Col lg={12} md={12} sm={12}>
              <Card>
                <CardHeader>
                  <h5 className="mb-0">{data?.title}</h5>
                </CardHeader>
                <CardBody>
                <Button 
                    color="primary" 
                    // position top right
                    style={{position: "absolute", top: "0", right: "0"}}
                    onClick={goToJobListings}
                  >
                    View All Job Listings
                  </Button>
                  {isLoading && <Loader loaderColor={"text-dark"}></Loader>}
                  {!isLoading && (
                    <div className="text-center">
                      <Form onSubmit={applyJob}>
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                            Job Description
                          </Label>
                          <Col lg="10" className="text-start">
                            {renderJobDescription()}
                            {/* Link and other logic for View More/View Less can be added here */}
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="name"
                            className="col-form-label col-lg-2"
                          >
                            Full Name
                            <span className="text-danger">*</span>
                          </Label>
                          <Col lg="6">
                            <InputGroup>
                              <div className="input-group-text">
                                <i className="fas fa-user"></i>
                              </div>
                              <Input
                                id="name"
                                name="name"
                                type="text"
                                className="form-control rounded-0"
                                placeholder="Enter Full Name..."
                                onChange={e => setFullName(e.target.value)}
                              />
                            </InputGroup>
                            {isFullName && (
                              <div className="text-start my-1 text-danger">
                                Full Name is Required
                              </div>
                            )}
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="contact"
                            className="col-form-label col-lg-2"
                          >
                            Contact Number
                            <span className="text-danger">*</span>
                          </Label>
                          <Col lg="6">
                            <InputGroup>
                              <div className="input-group-text">
                                <i className="fas fa-phone-alt"></i>
                              </div>
                              <Input
                                id="contact"
                                name="contact"
                                type="text"
                                className="form-control rounded-0"
                                placeholder="Enter Contact Number..."
                                onChange={e => setPhone(e.target.value)}
                              />
                            </InputGroup>
                            {isPhone && (
                              <div className="text-start my-1 text-danger">
                                Contact Number is Required
                              </div>
                            )}
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label
                            htmlFor="email"
                            className="col-form-label col-lg-2"
                          >
                            Email Address
                            <span className="text-danger">*</span>
                          </Label>
                          <Col lg="6">
                            <InputGroup>
                              <div className="input-group-text">
                                <i className="fas fa-envelope"></i>
                              </div>
                              <Input
                                id="email"
                                name="email"
                                type="email"
                                className="form-control rounded-0"
                                placeholder="Enter Email Address..."
                                onChange={e => setEmail(e.target.value)}
                              />
                            </InputGroup>
                            {isEmail && (
                              <div className="text-start my-1 text-danger">
                                Email is Required
                              </div>
                            )}
                          </Col>
                        </FormGroup>
                        <FormGroup className="mb-4" row>
                          <Label className="col-form-label col-lg-2">
                            Attached Files
                            <span className="text-danger">*</span>
                          </Label>
                          <Col lg="6">
                            {isDropZoneVisible ? (
                              <Dropzone
                                onDrop={acceptedFiles => {
                                  handleAcceptedFiles(acceptedFiles)
                                }}
                                maxFiles={1}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone">
                                    <div
                                      className="dz-message needsclick"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="dz-message needsclick">
                                        <div className="mb-3">
                                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                                        </div>
                                        <h4>
                                          Drop a file here or click to upload.
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                            ) : (NONE)}
                            <div
                              className="dropzone-previews mt-3"
                              id="file-previews"
                            >
                              {selectedFiles.map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <i className="bx bxs-file-pdf display-5"></i>
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {f.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                          </p>
                                        </Col>
                                        <Col className="col-auto">
                                          <button
                                            type="button"
                                            onClick={() => removeFile(f.name)}
                                            style={{
                                              backgroundColor: "transparent",
                                              border: "none",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-times"
                                              style={{
                                                // color: "red",
                                                fontSize: "15px",
                                              }}
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )
                              })}
                            </div>
                            {isFile && (
                              <div className="text-start my-1 text-danger">
                                Valid document is required
                              </div>
                            )}
                          </Col>
                        </FormGroup>
                        <Row className="justify-content-end">
                          <Col lg="3">
                            <Button
                              className="mx-2"
                              type="submit"
                              color="primary"
                            >
                              Apply for Job
                            </Button>
                            <Button color="secondary" onClick={cancel}>
                              Cancel
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default JobApplication
