import React, { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Alert,
} from "reactstrap"

import axiosInstance from "service/axiosConfig"
import Loader from "components/Common/loader"

const JobsApplications = () => {
  document.title = "Available Jobs | R24"
  const params = useParams()
  const [jobs, setJobs] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    const { job_id } = params
    axiosInstance
      .get(`/jobs/get_jobs/${job_id}`)
      .then(response => {
        setIsLoading(false)
        if (response.status >= 200 && response.status < 300) {
          setJobs(response.data)
        } else {
          throw new Error('Failed to fetch jobs')
        }
      })
      .catch(error => {
        setIsLoading(false)
        setError("There was an error fetching the jobs. Please try again later.")
        console.error("Error fetching jobs:", error)
      })
  }, [params])

  const applyForJob = (jobId) => {
    navigate(`/job_application/${jobId}`)
  }

  const renderJobDescription = (description) => {
    const truncatedDescription =
      description.length > 150
        ? `${description.slice(0, 150)}...`
        : description

    return (
      <p style={{ textAlign: 'justify', textJustify: 'inter-word', padding: '0px 8px', margin: '5px 0' }}>
        {truncatedDescription}
      </p>
    )
  }

  return (
    <React.Fragment>
      <section className="section hero-section bg-ico-hero" id="home">
        <div className="bg-overlay bg-primary"></div>
        <Container>
          {error && (
            <Alert color="danger">
              {error}
            </Alert>
          )}
          <Row className="align-items-center">
            <Col lg={12} md={12} sm={12}>
              <Card>
                <CardHeader>
                  <h5 className="mb-0">Available Jobs</h5>
                </CardHeader>
                <CardBody>
                  {isLoading ? (
                    <Loader loaderColor="text-dark" />
                  ) : (
                    <Row>
                      {jobs.map((job) => (
                        <Col key={job.id} lg={6} md={12} className="mb-4">
                          <Card>
                            <CardBody>
                              <h5>{job.title}</h5>
                              {renderJobDescription(job.job_description)}
                              <div className="mt-3">
                                <Button
                                  color="primary"
                                  onClick={() => applyForJob(job.guid)}
                                >
                                  Apply
                                </Button>
                                <Link
                                  to={`/job_application/${job.guid}`}
                                  className="btn btn-secondary ml-2"
                                >
                                  View Details
                                </Link>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  )
}

export default JobsApplications