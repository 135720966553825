import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  resumeResponse: [],
}

export const resumeSlice = createSlice({
  name: 'resume',
  initialState,
  reducers: {
    addResume: (state, action) => {
      state.resumeResponse.push(action.payload)
      state.resumeResponse.sort((a, b)=>b.overall_score - a.overall_score)
    },
    setIsOpen: (state, action) => {
      state.resumeResponse[action.payload].open = !(state.resumeResponse[action.payload].open)
    },
    setInitState: (state) => {
      state.resumeResponse = []
    }
  },
})


export const { addResume, setIsOpen, setInitState } = resumeSlice.actions
export default resumeSlice.reducer