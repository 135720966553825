import React from "react"
import { Modal, ModalBody } from "reactstrap"

const ResumeModal = ({ toggle, modal, documentUrl }) => {
//   console.log("Entering Resume Modal")
  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <ModalBody>
        <div>
          <div className="modal-content">
            <iframe src={documentUrl} width="100%" height="600px"/>
            {/* <iframe
                src={`https://docs.google.com/gview?url=${encodeURIComponent(documentUrl)}&embedded=true`}
                style={{ width: "100%", height: "500px" }}
                >
                </iframe> */}
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ResumeModal
