import React, { useEffect } from "react";
import withRouter from "components/Common/withRouter";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const history = useNavigate();
  useEffect(() => {
    localStorage.removeItem("authUser");
    history("/login");
  }, [])
  return <></>;
};

export default withRouter(Logout);