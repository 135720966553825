import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import axiosInstance from "../../../service/axiosConfig";
import NotificationComponent from "../../Common/NotificationWebSocket";

const NotificationDropdown = (props) => {
  const [menu, setMenu] = useState(false);
  const [IsNotified, setIsNotified] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const unreadNotifications = notifications.filter((notification) => !notification.is_read).length;

  const formatDateTime = (datetime) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    return new Date(datetime).toLocaleString(undefined, options);
  };

  const handleToggleReadStatus = async (notificationId, isRead) => {
    try {
      await axiosInstance.patch(`jobs/notifications/${notificationId}/`, {
        is_read: !isRead, // Toggle the is_read status
      });

      // Update the state to reflect the change
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === notificationId
            ? { ...notification, is_read: !isRead }
            : notification
        )
      );
    } catch (error) {
      console.error("Error updating read status:", error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      await axiosInstance.patch("jobs/notifications/update-all");
      // Update the state to reflect the change for all notifications
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) => ({ ...notification, is_read: true }))
      );
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axiosInstance.get("jobs/notifications");
        setNotifications(response.data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [IsNotified]);

  return (
    <React.Fragment>
      <NotificationComponent setIsNotified={setIsNotified}/>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          {unreadNotifications > 0 && (
            <span className="badge bg-danger rounded-pill">{unreadNotifications}</span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> Notifications </h6>
              </Col>
              <div className="col-auto">
                <button
                  className="btn btn-link text-decoration-none"
                  onClick={handleMarkAllAsRead}
                >
                  Read All
                </button>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {notifications.map((notification) => (
              <Link key={notification.id} to="" className="text-reset notification-item">
                <div className="d-flex">
                  <div className="avatar-xs me-3">
                    <span className="avatar-title bg-primary rounded-circle font-size-16">
                      {notification.title.charAt(0)}
                    </span>
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1">{notification.title}</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">{notification.message}</p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />
                        {formatDateTime(notification.timestamp)}
                      </p>
                    </div>
                  </div>
                  <div
                    className={`cursor-pointer me-1 ${
                      notification.is_read ? "text-success" : "text-danger"
                    }`}
                    onClick={() =>
                      handleToggleReadStatus(notification.id, notification.is_read)
                    }
                  >
                    {notification.is_read ? (
                      <i className="mdi mdi-email-open font-size-16"></i>
                    ) : (
                      <i className="mdi mdi-email-off font-size-16"></i>
                    )}
                  </div>
                </div>
              </Link>
            ))}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" to="#">
              <span key="t-view-more"></span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
